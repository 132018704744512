(() => {
  const initResourceList = $resourceList => {
    const resultsPerPage = 9;
    let selectedPage = 1,
      resourceFilterArr = [],
      initialLoad = true;
    const resourcesId = 'resources';
    let $visibleCards, $checkboxes, $productCards;
    const visibilityClass = 'product-card--visible';

    const initVariables = () => {
      $checkboxes = $resourceList.querySelectorAll('.emu-form-checkbox__input');
      $productCards = $resourceList.querySelectorAll('.product-card');

      // fetch the selected filters from localStorage and update the checkboxes
      const resourceFilters = localStorage.getItem('resourceFilters');

      if (resourceFilters) {
        resourceFilterArr = JSON.parse(resourceFilters);

        for (const filter of resourceFilterArr) {
          $checkboxes.forEach(($checkbox: HTMLInputElement) => {
            if ($checkbox.value === filter) {
              // using `.click()` as this fires the `change` event which is consistent with the event listener setup
              $checkbox.click();
            }
          });
        }
      }
    };

    //To Make the cards visible by adding 'visible' class
    const updateProductCardVisibility = (checkboxes, productCards) => {
      const selectedFilters = Array.from(checkboxes)
        .filter(checkbox => (checkbox as HTMLInputElement).checked)
        .map(checkbox => (checkbox as HTMLInputElement).value);

      // store the selected filters in localStorage
      localStorage.setItem('resourceFilters', JSON.stringify(selectedFilters));

      $visibleCards = Array.from(productCards).filter(card => {
        const cardFilter =
          (card as HTMLElement).getAttribute('data-filter') || '';
        return (
          selectedFilters.length === 0 || selectedFilters.includes(cardFilter)
        );
      });

      const start = (selectedPage - 1) * resultsPerPage;
      const end = start + resultsPerPage;

      productCards.forEach(card => {
        card.classList.remove(visibilityClass);
      });

      $visibleCards.forEach((card, index) => {
        if (index >= start && index < end) {
          (card as HTMLElement).classList.add(visibilityClass);
        }
      });
    };

    // To initiate the Pagination and to update it
    const updatePagination = visibleCardsLength => {
      window.Bus.emit('emu-pagination:change', {
        paginatedCompId: resourcesId,
        currentPage: selectedPage,
        resultsPerPage: resultsPerPage,
        totalResults: visibleCardsLength,
      });
    };

    const appendEvents = () => {
      if ($checkboxes?.length) {
        $checkboxes.forEach(checkbox => {
          checkbox.addEventListener('change', () => {
            selectedPage = 1;
            updatePagination($visibleCards.length);
            updateProductCardVisibility($checkboxes, $productCards);
            updatePagination($visibleCards.length);
          });
        });
      }

      //To update the cards on pagination update
      window.Bus.on(
        'emu-pagination:update',
        ({ paginatedCompId, currentPage }) => {
          if (paginatedCompId === 'resources') {
            selectedPage = currentPage;
            updateProductCardVisibility($checkboxes, $productCards);
            $resourceList?.scrollIntoView();
          }
        }
      );
    };

    initVariables();
    appendEvents();
    updatePagination($productCards?.length);
    updateProductCardVisibility($checkboxes, $productCards);

    // on initial page load, update the pagination after all the cards have been loaded and `$visibleCards` has some value
    if (initialLoad && resourceFilterArr.length > 0) {
      selectedPage = 1;
      updatePagination($visibleCards.length);
      initialLoad = false;
    }
  };

  const init = () => {
    const $resourceList = document.querySelectorAll('.resources-list');
    $resourceList.forEach($el => {
      initResourceList($el);
    });
  };

  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})();
